<template>
  <section
    class="w-100"
    :class="!wide ? 'slide--vertical' : ''"
  >
    <h1 v-if="title !== ''" class="mb-xl-5">{{title}}</h1>
    <article class="d-flex justify-content-around flex-column flex-xl-row">
      <Point
        v-for="(point, index) of points"
        :key="index"
        :icon="point.icon ? point.icon : 'exempt_icon'"
        :title="point.title ? point.title : ''"
        :description="point.description"
      />
    </article>
  </section>
</template>

<script>
import Point from './Point/Point.vue';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    points: {
      type: Array,
      required: true,
    },
    wide: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Point,
  },
};
</script>

<style lang="scss" scoped src="./KeyPoints.scss"></style>
