export default {
  dark: '#3b4a5a',
  darker: '#2d3846',
  success: '#41c6fb',
  primary: '#41c6fb',
  red: '#ee5b5b',
  green: '#baf2ba',
  orange: '#f2835d',
  yellow: '#fcef69',
};
