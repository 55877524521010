<template>
  <figure class="m-0" data-cy="key-point">
    <img :src="require(`@/assets/svg/${icon}.svg`)" :alt="icon" class="my-4 my-xl-5">
    <h2 v-if="title !== ''">{{title}}</h2>
    <h3 class="mx-xl-3">{{description}}</h3>
  </figure>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: 'Placeholder description',
    },
  },
};
</script>

<style lang="scss" scoped src="./Point.scss"></style>
