<template>
  <b-toast
    id="consent-toast"
    title="Consent"
    :visible="consentNotStated"
    no-auto-hide
    no-close-button
    toaster="b-toaster-bottom-right"
    data-cy="consent-toast"
  >
    <span>
      We use Google Analytics to analyse website traffic.
      By clicking “Allow” you consent to store on your device the necessary cookies to enable this.
      <br>
      <a href="http://itccompliance.co.uk/privacy-statement.php" target="_blank">
        Privacy Policy <BIconBoxArrowUpRight />
      </a>
    </span>
    <div class="mt-2">
      <b-btn class="mr-3" variant="primary" @click="setConsent(true)">Allow</b-btn>
      <b-btn variant="outline-darker" @click="setConsent(false)">Deny</b-btn>
    </div>
  </b-toast>
</template>

<script>
import { bootstrap } from 'vue-gtag';
import { BIconBoxArrowUpRight } from 'bootstrap-vue';

export default {
  data() {
    return {
      consentNotStated: localStorage.getItem('gtag-consent') === null,
    };
  },
  components: {
    BIconBoxArrowUpRight,
  },
  methods: {
    setConsent(bool) {
      localStorage.setItem('gtag-consent', bool);
      this.consentNotStated = false;
      if (bool === true) bootstrap();
    },
  },
};
</script>

<style lang="scss" scoped src="./Consent.scss"></style>
