<template>
  <div id="app" :class="{'frame': $route.name === 'Account'}">
    <Consent/>
    <Navbar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import Footer from '@/components/Footer/Footer.vue';
import Consent from '@/components/Consent/Consent.vue';
import { bootstrap } from 'vue-gtag';

export default {
  components: {
    Navbar,
    Footer,
    Consent,
  },
  created() {
    if (localStorage.getItem('gtag-consent') === 'true') bootstrap();
  },
};
</script>

<style lang="scss" src="@/scss/custom.scss"></style>
<style lang="scss" src="./App.scss"></style>
