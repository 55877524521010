<template>
  <section
    class="w-100 d-flex flex-column justify-content-center align-items-center"
    data-cy="legacy"
  >
    <div v-for="link of links" :key="link.name" class="h4 text-center">
      <a class="text-white" :href="link.href">Click here to access {{ link.name }}</a>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    links: [
      {
        name: 'Apex V3',
        href: process.env.VUE_APP_APEX_V3_URL,
      },
      {
        name: 'Apex Deal File',
        href: process.env.VUE_APP_EDF_URL,
      },
      {
        name: 'Training',
        href: process.env.VUE_APP_LEARNING_URL,
      },
      {
        name: 'Apex Classic',
        href: process.env.VUE_APP_APEX_CLASSIC_URL,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped src="./Legacy.scss"></style>
