<template>
  <figcaption
    class="d-flex flex-column justify-content-center align-items-center"
    @click="nextBenefit($event)"
    @keypress.enter="nextBenefit($event)"
    @mouseenter="paused = true"
    @focus="paused = true"
    @mouseleave="paused = false"
    @blur="paused = false"
    :ref="`benefit${benefits[currentBenefit].number}`"
    data-cy="benefit-circle"
  >
    <h1>Benefit #{{benefits[currentBenefit].number}}</h1>
    <h4>{{benefits[currentBenefit].text}}</h4>
    <gesture-tap-icon v-if="benefits.length > 1" title="See more benefits" />
  </figcaption>
</template>

<script>
import GestureTapIcon from 'vue-material-design-icons/GestureTap.vue';

export default {
  data() {
    return {
      currentBenefit: 0,
      paused: false,
      interval: null,
    };
  },
  props: {
    benefits: {
      type: Array,
      required: true,
    },
    cycleInterval: {
      type: Number,
      default: 6000,
    },
  },
  components: {
    GestureTapIcon,
  },
  methods: {
    nextBenefit() {
      if (this.benefits.length > 1) {
        const el = this.$refs[`benefit${this.benefits[this.currentBenefit].number}`];
        if (window.innerWidth > 992) {
          const width = el.clientWidth;
          el.style.marginLeft = `${width / 2}px`;
          el.style.marginRight = `${width / 2}px`;
        }
        el.classList.add('transitioning');
        setTimeout(() => {
          if (this.currentBenefit === this.benefits.length - 1) this.currentBenefit = 0;
          else this.currentBenefit += 1;
          el.style.removeProperty('margin-left');
          el.style.removeProperty('margin-right');
          el.classList.remove('transitioning');
        }, 500);
      }
    },
    startCycling() {
      this.interval = setInterval(() => {
        this.nextBenefit();
      }, this.cycleInterval);
    },
  },
  watch: {
    paused() {
      if (!this.paused) this.startCycling();
      else clearInterval(this.interval);
    },
    $route() {
      clearInterval(this.interval);
      this.currentBenefit = 0;
      this.startCycling();
    },
  },
  mounted() {
    this.startCycling();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped src="./BenefitCircle.scss"></style>
