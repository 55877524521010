<template>
  <b-nav-item
    :to="`/${section.route}`"
    class="mr-2 mx-xxl-auto p-0"
    :style="`border-color: ${section.color}; color: ${section.color};`"
  >
    <span class="d-none d-xxl-block">
      {{section.name}}
    </span>
    <span class="d-block d-xxl-none" style="max-width: 67%;">
      {{section.longName || section.name}}
    </span>
    <Arrow direction="right" :theme="section.theme" class="d-xxl-none" />
  </b-nav-item>
</template>

<script>
import SiteSection from '@/models/SiteSection';
import Arrow from '@/components/Arrow/Arrow.vue';

export default {
  props: {
    section: {
      type: SiteSection,
      required: true,
    },
  },
  components: {
    Arrow,
  },
};
</script>

<style lang="scss" scoped src="./NavbarSection.scss"></style>
