<template>
  <section
    class="d-flex flex-column justify-content-center align-items-center p-3"
    data-cy="find-out-more"
  >
    <div
      class="findoutmore__image"
      :style="`background: url(${require(`@/assets/img/hero/${img}.jpeg`)});`"
      ref="findOutMoreImage"
    />
    <h1>{{title}}</h1>
    <h5 class="w-100 my-5">{{description}}</h5>
    <b-btn
      :to="`/${to}`"
      :variant="`outline-${theme}`"
      size="lg"
      block
      ref="findOutMoreBtn"
    >
      Find out more
    </b-btn>
    <div class="findoutmore__accent-bar">
      <div
        class="findoutmore__accent-bar__fill"
        :style="`background: ${color};`"
        ref="findOutMoreAccentFill"
      />
    </div>
  </section>
</template>

<script>
import themeColors from '@/assets/themeColours';
import { throttle } from 'lodash';

export default {
  data() {
    return {
      detectFocusThrottled: throttle(this.detectFocus, 250),
    };
  },
  props: {
    title: {
      type: String,
      default: 'Placeholder title',
    },
    description: {
      type: String,
      default: 'Placeholder description',
    },
    img: {
      type: String,
      default: 'ar_hero',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    color() {
      return themeColors[this.theme];
    },
  },
  methods: {
    detectFocus() {
      const btn = this.$refs.findOutMoreBtn.$el;
      const img = this.$refs.findOutMoreImage;
      const fill = this.$refs.findOutMoreAccentFill;
      const rect = btn.getBoundingClientRect();
      const isInViewport = (r) => (
        r.top >= 0
        && r.left >= 0
        && r.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && r.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
      if (isInViewport(rect)) {
        fill.classList.add('active');
        img.classList.add('active');
      } else {
        fill.classList.remove('active');
        img.classList.remove('active');
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.detectFocusThrottled);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.detectFocusThrottled);
  },
};
</script>

<style lang="scss" scoped src="./FindOutMore.scss"></style>
