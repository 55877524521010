<template>
  <i
    :class="`arrow ${direction}`"
    :style="`border-color: ${color}; padding: ${size};`"
  />
</template>

<script>
import themeColors from '@/assets/themeColours';

export default {
  data() {
    return {
      color: themeColors[this.theme],
    };
  },
  props: {
    direction: {
      type: String,
      default: 'right',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '6px',
    },
  },
  watch: {
    $route() {
      this.color = themeColors[this.theme];
    },
  },
};
</script>

<style lang="scss" scoped src="./Arrow.scss"></style>
