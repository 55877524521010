<template>
  <a
    v-if="href"
    :href="href"
    rel="noopener noreferrer"
    target="_blank"
  >
    <img
      :src="src"
      alt="Logo"
      v-bind="$attrs"
    >
  </a>
  <img
    v-else
    :src="src"
    alt="Logo"
    v-bind="$attrs"
  >
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped src="./Logo.scss"></style>
