<template>
  <b-carousel v-model="slide" :interval="0" controls indicators data-cy="carousel">
      <b-carousel-slide v-for="(slide, index) in slides" :key="index">
        <img
          v-if="slide.backgroundImg"
          :alt="slide.backgroundImg"
          slot="img"
          :src="require(`@/assets/img/hero/${slide.backgroundImg}.jpeg`)"
        >
        <component
          :is="`${slide.type}Slide`"
          :title="slide.title || undefined"
          :description="slide.description || undefined"
          :points="slide.points || undefined"
          :image="slide.image || undefined"
          :text="slide.text || undefined"
        />
      </b-carousel-slide>
    </b-carousel>
</template>

<script>
import TitleSlide from './Slides/Title/Title.vue';
import KeyPointsSlide from './Slides/KeyPoints/KeyPoints.vue';
import ImageSlide from './Slides/Image/Image.vue';

export default {
  data() {
    return {
      slide: 0,
    };
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  components: {
    TitleSlide,
    KeyPointsSlide,
    ImageSlide,
  },
  watch: {
    $route() {
      this.slide = 0;
    },
  },
};
</script>

<style lang="scss" scoped src="./Carousel.scss"></style>
<style lang="scss">
// These components doesn't get the [data-v-*] attribute for scoping because of how bootstrap works
.carousel-inner {
  height: 100%;
}
.carousel-caption {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 40px;
  height: 40px;
}
</style>
