<template>
  <article class="px-3 mb-5 d-flex flex-column align-items-center">
    <h4 class="my-5">
      Whether you are looking to opt out of Direct Financial Services Regulation and fall under the
      umbrella of one of the UK's largest FCA Appointed Representative Networks, or need innovative
      systems, training, support and guidance to maintain your own regulatory standards, reduce
      regulatory risk and improve sales, ITC Compliance have it all.
    </h4>
    <hr class="my-5">
    <h1 class="my-5">
      ITC are the clear market leaders in providing regulatory support and exempt status as
      Appointed Representatives to the UK Retail Automotive sector. With a unique mix of sector
      specialists spread across the UK, a high level former regulator on ITC’s Board and a dedicated
      team of IT developers with a passion for innovation, why go anywhere else?
      <br><br>
      ITC are recognised not only as the UKs premier automotive solution, with a depth of regulatory
      knowledge and the ability to adapt to any secondary retail scenario, insurers and retailers
      alike look to ITC for support, no matter what their sector.
      <br>Here is a selection of a few of the hundreds of ITC clients you may recognise.
    </h1>
    <figure
      class="w-100 d-flex flex-wrap justify-content-around align-items-center"
    >
      <Logo :src="require('@/assets/img/logo-jct600.png')" alt="JCT600" />
      <Logo :src="require('@/assets/img/logo-trustford.png')" alt="Trust Ford" />
      <Logo :src="require('@/assets/img/logo-gh.png')" alt="Glyn Hopkins" />
      <Logo :src="require('@/assets/img/logo-toomey.png')" alt="Toomey Motor Group" href="https://www.toomeymotorgroup.co.uk/" />
    </figure>
    <ScrollHint dark/>
  </article>
</template>

<script>
import ScrollHint from '@/components/PageContent/ScrollHint/ScrollHint.vue';
import Logo from './Logo.vue';

export default {
  components: {
    Logo,
    ScrollHint,
  },
};
</script>

<style lang="scss" scoped src="./HomeInfo.scss"></style>
