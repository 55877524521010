<template>
  <header :class="!wide ? 'slide--vertical' : ''">
    <h1 class="mb-xl-5">{{title}}</h1>
    <h3 v-if="description !== ''">{{description}}</h3>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Placeholder Title',
    },
    description: {
      type: String,
      default: '',
    },
    wide: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped src="./Title.scss"></style>
