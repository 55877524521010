<template>
  <PageContent
    :title="`An alternative to Direct Financial Conduct Authority Authorisation\nand much more...`"
    v-bind="{ alerts }"
  >
    <HomeInfo slot="overlay-top-extra" />
    <main slot="overlay-info">
      <FindOutMore
        v-for="product of products"
        :key="product.route"
        :title="product.page.title"
        :description="product.page.description"
        :img="product.page.heroImg"
        :color="product.color"
        :theme="product.theme"
        :to="product.route"
      />
    </main>
  </PageContent>
</template>

<script>
import PageContent from '@/components/PageContent/PageContent.vue';
import HomeInfo from '@/components/PageContent/Overlay/HomeInfo/HomeInfo.vue';
import FindOutMore from '@/components/FindOutMore/FindOutMore.vue';
import { globalAlerts, homeAlerts } from '@/assets/alerts';
import products from '@/assets/products.json';

export default {
  components: {
    PageContent,
    HomeInfo,
    FindOutMore,
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    alerts() {
      return [
        ...globalAlerts,
        ...homeAlerts,
      ];
    },
  },
  created() {
    this.products = products.filter((product) => product.page);
  },
};
</script>
