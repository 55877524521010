<template>
  <div class="d-flex flex-column align-items-center" data-cy="scroll-hint">
    <h5 :class="`mb-3${dark ? ' dark' : ''}`">SCROLL</h5>
    <Arrow direction="down" size="10px" :theme="theme" />
  </div>
</template>

<script>
import Arrow from '@/components/Arrow/Arrow.vue';

export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'primary',
    },
  },
  components: {
    Arrow,
  },
};
</script>

<style lang="scss" scoped src="./ScrollHint.scss"></style>
