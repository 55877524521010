import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Product from '../views/Product.vue';
import Contact from '../components/Contact/Contact.vue';
import Legacy from '../components/Legacy/Legacy.vue';
import products from '../assets/products.json';

const productRoutes = [];
products.forEach((product) => {
  productRoutes.push({
    path: `/${product.route}`,
    name: product.name,
    component: Product,
    props: { product },
  });
});

Vue.use(VueRouter);

const staticRoutes = [
  {
    path: '/account',
    name: 'Account',
    component: Legacy,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '*',
    name: 'Home',
    component: Home,
  },
];
const routes = staticRoutes.concat(productRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
