import { render, staticRenderFns } from "./Consent.vue?vue&type=template&id=675fe804&scoped=true&"
import script from "./Consent.vue?vue&type=script&lang=js&"
export * from "./Consent.vue?vue&type=script&lang=js&"
import style0 from "./Consent.scss?vue&type=style&index=0&id=675fe804&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "675fe804",
  null
  
)

export default component.exports