<template>
  <header class="overlay__top">
    <div class="overlay__top__triangle">
      <div class="overlay__top__triangle__left"></div>
      <div class="overlay__top__triangle__right"></div>
    </div>
    <section class="overlay__top__lower">
      <img
        :src="require(`@/assets/img/overlay/${imgDt}.png`)"
        :alt="imgDt"
        class="d-none d-lg-block mb-5"
      />
      <div class="figurehead d-block d-lg-none mb-5">
        <img
          v-if="benefits.length === 0"
          :src="require(`@/assets/img/overlay/${imgMob === '' ? imgDt : imgMob}.png`)"
          :alt="imgMob === '' ? imgDt : imgMob"
        />
        <BenefitCircle v-else :benefits="numberedBenefits" :cycleInterval="5000" />
      </div>
      <figure
        v-if="benefits.length > 0"
        class="w-100 d-none d-lg-flex justify-content-between align-items-center px-5"
      >
        <BenefitCircle :benefits="oddBenefits" />
        <BenefitCircle :benefits="evenBenefits" />
      </figure>
      <slot />
    </section>
  </header>
</template>

<script>
import BenefitCircle from './BenefitCircle/BenefitCircle.vue';

export default {
  props: {
    imgDt: {
      type: String,
      default: 'ITC_car',
    },
    imgMob: {
      type: String,
      default: '',
    },
    benefits: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    numberedBenefits() {
      return this.benefits.map((benefit) => ({
        number: this.benefits.indexOf(benefit) + 1,
        text: benefit,
      }));
    },
    oddBenefits() {
      return this.numberedBenefits.filter((benefit) => benefit.number % 2 !== 0);
    },
    evenBenefits() {
      return this.numberedBenefits.filter((benefit) => benefit.number % 2 === 0);
    },
  },
  components: {
    BenefitCircle,
  },
};
</script>

<style lang="scss" scoped src="./Overlay.scss"></style>
