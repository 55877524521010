<template>
  <b-navbar
    toggleable="xxl"
    fixed="top"
    type="dark"
    variant="dark"
    class="py-0 px-xxl-5"
    data-cy="navbar"
  >
    <b-navbar-brand to="/" class="py-3">
      <img :src="require('@/assets/svg/itc_logo.svg')" alt="ITC Logo" class="h-100" />
    </b-navbar-brand>

    <b-navbar-nav class="d-block d-xxl-none mx-auto pt-1">
      <a class="text-white" href="tel:0845 177 2266">0845 177 2266</a>
    </b-navbar-nav>

    <!-- Accont button to left of burger menu on small screens -->
    <b-navbar-nav class="d-block d-xxl-none">
      <b-nav-item to="/account" class="btn-account" @click="accountIconClicked">
        <BIconPersonFill variant="primary" style="height: 42px; width: 42px;"/>
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav v-model="visible">
      <b-navbar-nav class="mx-xxl-auto nav-links">
        <NavbarSection
          v-for="section of sections"
          :key="section.name"
          :section="section"
        />
      </b-navbar-nav>
    </b-collapse>

    <!-- Account button to right of tabs on xxl screens -->
    <b-navbar-nav class="d-none d-xxl-block">
      <b-nav-item to="/account" class="btn-account">
        <BIconPersonFill variant="primary" style="height: 42px; width: 42px;"/>
        <span>Log In</span>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { BIconPersonFill } from 'bootstrap-vue';
import NavbarSection from '@/components/NavbarSection/NavbarSection.vue';
import SiteSection from '@/models/SiteSection';
import products from '@/assets/products.json';

export default {
  data() {
    return {
      sections: [],
      visible: false,
    };
  },
  methods: {
    accountIconClicked() {
      if (this.visible === true) this.$root.$emit('bv::toggle::collapse', 'nav-collapse');
    },
  },
  components: {
    BIconPersonFill,
    NavbarSection,
  },
  created() {
    this.sections.push(new SiteSection('Home', null, ''));
    products.forEach((product) => {
      this.sections.push(new SiteSection(
        product.name,
        product.longName,
        product.route,
        product.theme,
      ));
    });
    this.sections.push(new SiteSection('Contact', 'Contact ITC Compliance', 'contact'));
  },
};
</script>

<style lang="scss" scoped src="./Navbar.scss"></style>
