import { render, staticRenderFns } from "./Point.vue?vue&type=template&id=37a16d6a&scoped=true&"
import script from "./Point.vue?vue&type=script&lang=js&"
export * from "./Point.vue?vue&type=script&lang=js&"
import style0 from "./Point.scss?vue&type=style&index=0&id=37a16d6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a16d6a",
  null
  
)

export default component.exports