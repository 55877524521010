/* eslint-disable default-param-last */
import themeColors from '@/assets/themeColours';

/**
 * Models a section of the site
 * @param shortName - name shown on horizontal navbar
 * @param longName - name shown in dropdown navbar
 * @param colorHex - accent color for this section
 */
export default class SiteSection {
  constructor(shortName, longName = null, route, theme = 'primary') {
    this.name = shortName;
    this.longName = longName;
    this.route = route;
    this.theme = theme;
    this.color = themeColors[theme];
  }
}
