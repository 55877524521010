<template>
  <article
    class="d-flex flex-row justify-content-center justify-content-xl-between align-items-center
      py-5 px-3 px-xl-0"
    :class="!wide ? 'slide--vertical' : ''"
  >
    <img :src="require(`@/assets/img/${image}.png`)" :alt="image" class="mb-3">
    <h5 class="ml-xl-5">{{text}}</h5>
  </article>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: 'overlay/ITC_car',
    },
    text: {
      type: String,
      default: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
    },
    wide: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped src="./Image.scss"></style>
