<template>
  <PageContent
    :title="product.page.title"
    :description="product.page.description"
    :heroImg="product.page.heroImg"
    :theme="product.theme"
    :productContent="product.page"
  >
    <Carousel v-if="wide" :slides="product.page.slides" slot="overlay-info"/>
    <article
      v-else
      slot="overlay-info"
      class="d-flex flex-column align-items-center"
      :style="`background: #FFF; text-align: center; color: ${darkTextColor};`"
      data-cy="vertical-slides"
    >
      <component
        v-for="(slide, index) in verticalSlides"
        :key="index"
        :is="`${slide.type}Slide`"
        :title="slide.title || undefined"
        :description="slide.description || undefined"
        :points="slide.points || undefined"
        :image="slide.image || undefined"
        :text="slide.text || undefined"
        :wide="false"
        class="w-100"
      />
    </article>
  </PageContent>
</template>

<script>
import PageContent from '@/components/PageContent/PageContent.vue';
import Carousel from '@/components/Carousel/Carousel.vue';
import TitleSlide from '@/components/Carousel/Slides/Title/Title.vue';
import KeyPointsSlide from '@/components/Carousel/Slides/KeyPoints/KeyPoints.vue';
import ImageSlide from '@/components/Carousel/Slides/Image/Image.vue';
import { cloneDeep } from 'lodash';
import themeColors from '@/assets/themeColours';

export default {
  data() {
    return {
      width: window.innerWidth,
      darkTextColor: themeColors.darker,
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    PageContent,
    Carousel,
    TitleSlide,
    KeyPointsSlide,
    ImageSlide,
  },
  computed: {
    wide() {
      return this.width >= 1200; // xl
    },
    verticalSlides() {
      // removes identical titles from consecutive slides
      const slides = cloneDeep(this.product.page.slides);
      return slides.map((slide, index) => {
        const newSlide = slide;
        if (index > 0 && slide.type === 'KeyPoints') {
          const lastSlideWithTitle = slides.slice(0, index).reverse().find((s) => s.title && s.title !== '');
          if (lastSlideWithTitle.title === slide.title) {
            newSlide.title = '';
          }
        }
        return newSlide;
      });
    },
  },
  created() {
    window.onresize = () => {
      this.width = window.innerWidth;
    };
  },
};
</script>

<style lang="scss" src="@/components/Carousel/Slides/vertical.scss"></style>
