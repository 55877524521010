import 'core-js/features/array/from';
import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: 'G-79V3SCL2T0' },
  bootstrap: false,
}, router);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
